import React, { useState } from 'react';
import Layout from '../components/layout';
import * as AmbassadorProgramService from '../services/ambassador-program-service';
import {
    Container,
    Col,
    Row,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Button
} from 'reactstrap';
import { Link } from 'gatsby';
import { css } from 'emotion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import logo from '../images/logo.svg'
import Modal from '../components/success-modal';

const siteMeta = {
  subtitle: 'Ambassador Program',
  path: '/ambassador-program',
  openGraphTitle: 'AmbassadorProgram'
};

const successMessage = () => {
  return(
    <div>
      Your form has been sent successfully! We’ll be in touch shortly.
      In the meantime check out <Link to='/blog'>our blog page </Link> to see what we’ve been up to.
    </div>
  );
}

const ContactUsSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Your name must be longer than 2 characters')
    .required('Please specify your name'),
  email: Yup.string()
    .email('Please enter a valid email address e.g. name@domain.com')
    .required('We need your email address to contact you'),
  phone: Yup.string()
  .required('Please specify your phone')
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(10, 'Must be exactly 10 digits')
  .max(10, 'Must be exactly 10 digits'),
  linkedin: Yup.string(),
  terms: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted.")
});

const initialValues={
  name: '',
  email: '',
  phone: '',
  linkedin: '',
  terms: false,
};

const AmbassadorProgram = () => {
  const [modal, setModal] = useState(false);
    return (
        <Layout siteMeta={siteMeta}>
            <Container className="mt-3">
                <Row>
                    <Col md="6" className="my-5">
                        <div className="navbar-brand" css={css`width:300px;`} >
                            <img className="mb-0 p-0" css={css`width:100%; height:auto;`} src={logo} alt="Logo" />
                        </div>
                        <p css={css`font-weight: 500;`} className="mt-5 mb-4">Leading LATAM Nearshore Software Services</p>
                        <hr className="mt-2 mb-5 ml-0" css={css`width: 100px;`}></hr>
                        <h4 css={css`font-size: 44px;`}>Profitable Partnerships<br/>through Networking</h4>
                        <p css={css`font-size: 19px;`}>Enjoy handsome earnings by making<br/>introductions to our team.</p>
                    </Col>
                    <Col md="6" className="my-5">
                        <legend className="mb-3">Apply to participate in our Ambassador Program!</legend>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ContactUsSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                AmbassadorProgramService.sendForm(values)
                                    .then(
                                    () => {
                                        setSubmitting(false);
                                        resetForm(initialValues);
                                        setModal(true);
                                    },
                                    err => {
                                        setSubmitting(false)
                                        alert(err.message)
                                    }
                                    )
                            }}
                        >
                            {({ errors, isSubmitting, touched }) => (
                            <Form>
                                <FormGroup>
                                <Label for="name">Name *</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    tag={Field}
                                    invalid={Boolean(touched.name && errors.name)}
                                    aria-required
                                />
                                <ErrorMessage name="name" component={FormFeedback} />
                                </FormGroup>
                                <FormGroup>
                                <Label for="email">Email *</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    tag={Field}
                                    invalid={Boolean(touched.email && errors.email)}
                                    aria-required
                                />
                                <ErrorMessage name="email" component={FormFeedback} />
                                </FormGroup>
                                <FormGroup>
                                <Label for="phone">Phone *</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    tag={Field}
                                    invalid={Boolean(touched.phone && errors.phone)}
                                    aria-required
                                />
                                <ErrorMessage name="phone" component={FormFeedback} />
                                </FormGroup>
                                <FormGroup>
                                <Label for="company">LinkedIn URL</Label>
                                <Input
                                    type="text"
                                    name="linkedin"
                                    id="linkedin"
                                    tag={Field}
                                    invalid={Boolean(touched.linkedin && errors.linkedin)}
                                />
                                <ErrorMessage name="linkedin" component={FormFeedback} />
                                </FormGroup>
                                <FormGroup>
                                <Input
                                    type="checkbox"
                                    className="ml-0 my-3"
                                    name="terms"
                                    id="terms"
                                    tag={Field}
                                    invalid={Boolean(touched.terms && errors.terms)}
                                    css={css`position:unset;`}
                                    aria-required
                                />
                                {' '}
                                <Label check>
                                    I have read and understood the <a href='/privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href='/referral-agreement' target="_blank" rel="noopener noreferrer">terms and conditions</a>
                                </Label>
                                <ErrorMessage name="terms" component={FormFeedback} />
                                </FormGroup>
                                <Button color="danger" type="submit" disabled={isSubmitting}>
                                Submit
                                </Button>
                            </Form>
                            )}
                        </Formik>
                        <Modal
                            show={ modal }
                            toggle={ (value) => setModal(!value) }
                            title='Success'
                            body={ successMessage() }
                            centered
                            closeButtonText='Close'
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default AmbassadorProgram;
